import React, { useEffect, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useConfig } from "../../contexts/configContext";
import ListTable from "../SharedComponents/ListTable";
import useHttpApi from "../useHttpApi";
import { isNonEmptyObject, isNonEmptyString } from "../../utils";
import Modal from "react-bootstrap/Modal";

function KPI(props) {
  const [cvasCounters, setcvasCounters] = useState(null);
  const [pvasCounters, setpvasCounters] = useState(null);
  // const [domainTable, setdomainTable] = useState(null);
  // const [tdr, settdr] = useState(null);
  const [tdrCounters, settdrCounters] = useState(null);
  // const [routingTable, setroutingTable] = useState(null);
  // const [domainsInRoutes, setdomainsInRoutes] = useState(null);
  const [cvasTable, setcvasTable] = useState([]);
  const [pvasTable, setpvasTable] = useState([]);
  const [cvasTdr, setcvasTdr] = useState([]);
  const [pvasTdr, setpvasTdr] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [modalContent, setmodalContent] = useState(null);

  const config = useConfig();
  const httpApi = useHttpApi();

  const inboundHeader = [
    {
      title: "Domain",
      field: "domain",
      size: "20%",
    },
    {
      title: "Filtered",
      field: "filtered",
      size: "20%",
    },
    {
      title: "Incoming",
      field: "incoming",
      size: "20%",
    },
    {
      title: "Manipulated",
      field: "manipulation",
      size: "20%",
    },
    {
      title: "Res Manipulated",
      field: "res_manipulation",
      size: "20%",
    },
  ];

  const outboundHeader = [
    {
      title: "Domain",
      field: "domain",
      size: "20%",
    },
    {
      title: "Filtered",
      field: "filtered",
      size: "20%",
    },
    {
      title: "Incoming",
      field: "incoming",
      size: "20%",
    },
    {
      title: "Manipulated",
      field: "manipulation",
      size: "20%",
    },
    {
      title: "Res Manipulated",
      field: "res_manipulation",
      size: "20%",
    },
  ];

  const inboundLogsHeader = [
    {
      title: "Method",
      field: "method",
      size: "7%",
    },
    {
      title: "Path",
      field: "path",
      size: "20%",
    },
    {
      title: "User-Agent",
      field: "user-agent",
      size: "10%",
    },
    {
      title: "Apiroot",
      field: "apiroot",
      size: "17%",
    },
    {
      title: "Destination PLMN",
      field: "plmn",
      size: "13%",
    },
    {
      title: "Response",
      field: "response",
      size: "10%",
    },
    {
      title: "Timestamp",
      field: "timestamp",
      size: "20%",
    },
    {
      title: "",
      field: "",
      size: "3%",
      type: "show",
    },
  ];

  const outboundLogsHeader = [
    {
      title: "Method",
      field: "method",
      size: "7%",
    },
    {
      title: "Path",
      field: "path",
      size: "20%",
    },
    {
      title: "User-Agent",
      field: "user-agent",
      size: "10%",
    },
    {
      title: "Apiroot",
      field: "apiroot",
      size: "17%",
    },
    {
      title: "Source PLMN",
      field: "plmn",
      size: "13%",
    },
    {
      title: "Response",
      field: "response",
      size: "10%",
    },
    {
      title: "Timestamp",
      field: "timestamp",
      size: "20%",
    },
    {
      title: "",
      field: "",
      size: "7%",
      type: "show",
    },
  ];

  useEffect(() => {
    props.setBreadcrumb("", "KPI", "");
  }, []);

  const buildVasTable = (original) => {
    // Step 1: Estrazione dei domini univoci
    const domains = {};
    for (const key in original) {
      const parts = key.split("|")[1];
      if (!domains[parts]) {
        domains[parts] = {
          domain: parts,
          filtered: 0,
          incoming: 0,
          manipulation: 0,
          res_manipulation: 0,
        };
      }
    }

    // Step 2: Calcolo dei valori appropriati per ogni dominio
    for (const key in original) {
      const parts = key.split("|")[1];
      const prefix = key.split("|")[0];
      if (domains[parts]) {
        domains[parts][prefix] = original[key];
      }
    }

    // Step 3: Costruzione dell'array di oggetti
    const processed = Object.values(domains).filter((el) =>
      isNonEmptyString(el.domain)
    );
    return processed;
  };

  // const resultExample = [
  //   {
  //     domain: "mnc001.mcc001",
  //     filtered: 17,
  //     incoming: 14,
  //     manipulation: 14,
  //     res_manipulation: 12,
  //   },
  //   {
  //     domain: "mnc001.mcc002",
  //     filtered: 12,
  //     incoming: 221,
  //     manipulation: 100,
  //     res_manipulation: 4,
  //   },
  //   {
  //     domain: "mnc002.mcc003",
  //     filtered: 10,
  //     incoming: 123,
  //     manipulation: 96,
  //     res_manipulation: 4,
  //   },
  // ]

  useEffect(() => {
    fetchCounters().then((res) => {
      setcvasTable(buildVasTable(res.cvas));
      setpvasTable(buildVasTable(res.pvas));
      setcvasCounters(res.cvas);
      setpvasCounters(res.pvas);
    });

    // fetchDomainTable().then((res) => {
    //   setdomainTable(res);
    // });

    // fetchTDR().then((res) => {
    //   settdr(res);
    // });

    // fetchRoutingTable().then((res) => setroutingTable(res));

    setInterval(
      () =>
        fetchCounters().then((res) => {
          setcvasTable(buildVasTable(res.cvas));
          setpvasTable(buildVasTable(res.pvas));
          setcvasCounters(res.cvas);
          setpvasCounters(res.pvas);
        }),
      5000
    );
  }, []);

  // useEffect(() => {
  //   domainTable && routingTable && findDomainsInRoutingTable();
  // }, [domainTable, routingTable]);

  const fetchCounters = async () => {
    let cvas = await httpApi.callGetAPI({ url: `h_json/cvas_counters` });
    let pvas = await httpApi.callGetAPI({ url: `h_json/pvas_counters` });
    return { cvas, pvas };
  };

  const fetchDomainTable = async () => {
    return await httpApi.callGetAPI({ url: `json/domaintable` });
  };

  const fetchRoutingTable = async () => {
    return await httpApi.callGetAPI({ url: `json/routing` });
  };

  const resetCounters = async () => {
    await httpApi.callDeleteAPI({ url: `h_reset/cvas_counters` });
    await httpApi.callDeleteAPI({ url: `h_reset/pvas_counters` });

    fetchCounters().then((res) => {
      setcvasTable(buildVasTable(res.cvas));
      setpvasTable(buildVasTable(res.pvas));
      setcvasCounters(res.cvas);
      setpvasCounters(res.pvas);
    });
  };

  const downloadCounters = () => {
    let vasObject = { pvas: pvasCounters, cvas: cvasCounters };
    console.log(vasObject);

    const blob = new Blob([JSON.stringify(vasObject, null, 2)], {
      type: "application/json",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "vasCounters.json";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // const findDomainsInRoutingTable = () => {
  //   let _domainInRoutes = [];
  //   for (let route of routingTable) {
  //     let found = false;
  //     for (let domain of domainTable) {
  //       if (route.domain_name === domain.domain_name) {
  //         _domainInRoutes = [..._domainInRoutes, domain];
  //         found = true;
  //         break;
  //       }
  //     }
  //     if (found) continue;
  //   }
  //   setdomainsInRoutes(_domainInRoutes);
  // };

  // useEffect(() => {
  //   if (domainsInRoutes && tdr) {
  //     let _tdrCounters = domainsInRoutes.map((d) => {
  //       return { dns: d.dns, counter: 0 };
  //     });
  //     tdr.forEach((_tdr) => {
  //       _tdrCounters.forEach((tdrc) => {
  //         if (_tdr.apiroot.includes(tdrc.dns)) {
  //           tdrc.counter++;
  //         }
  //       });
  //     });
  //     _tdrCounters = _tdrCounters.filter((t) => t.counter > 0);
  //     settdrCounters(_tdrCounters);
  //   }
  // }, [domainsInRoutes, tdr]);

  const formatTime = (vas) => {
    return vas.map((el) => {
      const dateTime = el.timestamp.replace("T", " ").split(".");
      const date = dateTime[0];
      const timezone = dateTime[1].includes("+")
        ? dateTime[1].split("+")[1]
        : dateTime[1].split("-")[1];
      el.timestamp = dateTime[1].includes("+")
        ? `${date} +${timezone}`
        : `${date} -${timezone}`;
      return el;
    });
  };

  useEffect(() => {
    fetchTDR().then((res) => {
      res && res[0] ? setcvasTdr(formatTime(res[0])) : setcvasTdr([]);
      res && res[1] ? setpvasTdr(formatTime(res[1])) : setpvasTdr([]);
    });
  }, []);

  const fetchTDR = async () => {
    await httpApi.callGetAPI({ url: "list_json/cvas_tdr_repo" });
    let res = Promise.all([
      await httpApi.callGetAPI({ url: "list_json/cvas_tdr_repo" }),
      await httpApi.callGetAPI({ url: "list_json/pvas_tdr_repo" }),
    ]);
    return res;
  };

  const resetTdr = async () => {
    await httpApi.callDeleteAPI({
      url: "h_reset/cvas_tdr_repo",
      snack: {
        success: { status: "success", message: "TDR Table successfully reset" },
      },
    });

    fetchTDR().then((res) => {
      res && res[0] ? setcvasTdr(formatTime(res[0])) : setcvasTdr([]);
      res && res[1] ? setpvasTdr(formatTime(res[1])) : setpvasTdr([]);
    });
  };

  const downloadTdr = async (tab) => {
    await httpApi.callDownloadAPI(
      {
        url: `list_json/${tab}_tdr_repo/export`,
        snack: {
          success: {
            status: "success",
            message: "TDR Table successfully exported",
          },
        },
      },
      {
        name: `${tab === "cvas" ? "Inbound" : "Outbound"}TDRTable`,
        type: "excel",
      }
    );
  };

  const getMessages = async (vasType) => {
    const data = await httpApi.callGetAPI({
      url:
        vasType === "cvas"
          ? "json/cSEPP_last_tdr_audit"
          : "json/pSEPP_last_tdr_audit",
    });
    if (data) setmodalContent(data);
    else {
      data = `There are no samples to show`;
      setmodalContent(data);
    }
    setshowModal(true);
  };

  return (
    <>
      {/* <Spinner show={config.loading} /> */}
      {config.loading ? null : (
        <>
          <Modal
            size="lg"
            show={showModal}
            dialogClassName="tdrModal text-center"
          >
            <Modal.Header className="d-flex justify-content-center ">
              <Modal.Title>Last Sample</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflow: "auto", maxHeight: "70vh" }}>
              <pre style={{ textAlign: "left" }}>
                {JSON.stringify(modalContent, null, 2)}
              </pre>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  setmodalContent(null);
                  setshowModal(false);
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="page">
            <Tabs
              defaultActiveKey="Inbound"
              id="sepp-tabs-kpi"
              className="mb-3"
              justify
            >
              <Tab eventKey="Inbound" title="INBOUND COUNTERS">
                <h5 className="title mt-5">Inbound Global Counters</h5>
                <div className="rowDiv j-evenly">
                  <div className="colDiv" style={{ width: "40%" }}>
                    <div className="data-row">
                      <span>Total:</span>
                      <span>{cvasCounters?.incoming || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Filtered:</span>
                      <span>{cvasCounters?.filtered || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Manipulated:</span>
                      <span>{cvasCounters?.manipulation || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Handshake Sent:</span>
                      <span>{cvasCounters?.handshake_req_sent || 0}</span>
                    </div>
                    <div className="separator"></div>
                  </div>
                  <div className="colDiv" style={{ width: "40%" }}>
                    <div className="data-row">
                      <span>Handshake Successful:</span>
                      <span>{cvasCounters?.handshake_req_success || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Handshake Failed:</span>
                      <span>{cvasCounters?.handshake_req_failed || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Handshake Not Answered:</span>
                      <span>{cvasCounters?.handshake_req_timeout || 0}</span>
                    </div>
                    <div className="separator"></div>
                  </div>
                </div>
                {cvasTable.length > 0 ? (
                  <>
                    <ListTable
                      dense
                      elements={cvasTable}
                      header={inboundHeader}
                      sortable
                      searchable
                      collapsible
                    ></ListTable>
                    <div style={{ float: "right", marginRight: "1.5%" }}>
                      <Button onClick={() => downloadCounters()}>
                        DOWNLOAD
                      </Button>
                      <Button
                        onClick={() => resetCounters()}
                        style={{ marginLeft: "20px" }}
                      >
                        RESET ALL
                      </Button>
                    </div>
                  </>
                ) : (
                  <h3 className="no-table-data">
                    There are no particular cases to show
                  </h3>
                )}
              </Tab>
              <Tab eventKey="Outbound" title="OUTBOUND COUNTERS">
                <h5 className="title mt-5">Outbound Global Counters</h5>
                <div className="rowDiv j-evenly">
                  <div className="colDiv" style={{ width: "40%" }}>
                    <div className="data-row">
                      <span>Total:</span>
                      <span>{pvasCounters?.incoming || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Filtered:</span>
                      <span>{pvasCounters?.filtered || 0}</span>
                    </div>
                    <div className="separator"></div>

                    <div className="data-row">
                      <span>Manipulated:</span>
                      <span>{pvasCounters?.manipulation || 0}</span>
                    </div>
                    <div className="separator"></div>
                  </div>
                  <div className="colDiv" style={{ width: "40%" }}>
                    <div className="data-row">
                      <span>Handshake Received:</span>
                      <span>{pvasCounters?.handshake_req_sent || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Handshake Successful:</span>
                      <span>{pvasCounters?.handshake_req_success || 0}</span>
                    </div>
                    <div className="separator"></div>
                    <div className="data-row">
                      <span>Handshake Failed:</span>
                      <span>{pvasCounters?.handshake_req_failed || 0}</span>
                    </div>
                    <div className="separator"></div>
                  </div>
                </div>
                {pvasTable.length > 0 ? (
                  <>
                    <ListTable
                      dense
                      elements={pvasTable}
                      header={outboundHeader}
                      sortable
                      searchable
                      collapsible
                    ></ListTable>
                    <div style={{ float: "right", marginRight: "1.5%" }}>
                      <Button onClick={() => downloadCounters()}>
                        DOWNLOAD
                      </Button>
                      <Button
                        onClick={() => resetCounters()}
                        style={{ marginLeft: "20px" }}
                      >
                        RESET ALL
                      </Button>
                    </div>
                  </>
                ) : (
                  <h3 className="no-table-data">
                    There are no particular cases to show
                  </h3>
                )}
              </Tab>
              <Tab eventKey="Inbound Logs" title="INBOUND TRAFFIC LOGS">
                {cvasTdr.length > 0 ? (
                  <>
                    <ListTable
                      elements={cvasTdr}
                      header={inboundLogsHeader}
                      sortable
                      searchable
                      collapsible
                      dense
                      cellActions={[
                        {
                          elementName: "TDR",
                          type: "show",
                          size: inboundLogsHeader.find(
                            (h) => h.hasOwnProperty("type") && h.type === "show"
                          )?.size,
                        },
                      ]}
                    ></ListTable>
                    <div
                      className="rowDiv j-right list-group"
                      style={{ marginRight: "1.5%", marginTop: "0px" }}
                    >
                      <Button onClick={() => getMessages("cvas")}>
                        SHOW LAST SAMPLE
                      </Button>
                      <Button
                        onClick={() => downloadTdr("cvas")}
                        style={{ marginLeft: "20px" }}
                      >
                        DOWNLOAD
                      </Button>
                      <Button
                        onClick={() => resetTdr()}
                        style={{ marginLeft: "20px" }}
                      >
                        RESET ALL
                      </Button>
                    </div>
                  </>
                ) : (
                  <h3 className="no-table-data">
                    No Inbound TDR data available
                  </h3>
                )}
              </Tab>
              <Tab eventKey="Outbound Logs" title="OUTBOUND TRAFFIC LOGS">
                {pvasTdr.length > 0 ? (
                  <>
                    <ListTable
                      elements={pvasTdr}
                      header={outboundLogsHeader}
                      sortable
                      searchable
                      dense
                      cellActions={[
                        {
                          elementName: "TDR",
                          type: "show",
                          size: outboundLogsHeader.find(
                            (h) => h.hasOwnProperty("type") && h.type === "show"
                          )?.size,
                        },
                      ]}
                    ></ListTable>
                    <div
                      className="rowDiv j-right list-group"
                      style={{ marginRight: "1.5%", marginTop: "0px" }}
                    >
                      <Button onClick={() => getMessages("pvas")}>
                        SHOW LAST SAMPLE
                      </Button>
                      <Button
                        onClick={() => downloadTdr("pvas")}
                        style={{ marginLeft: "20px" }}
                      >
                        DOWNLOAD
                      </Button>
                      <Button
                        onClick={() => resetTdr()}
                        style={{ marginLeft: "20px" }}
                      >
                        RESET ALL
                      </Button>
                    </div>
                  </>
                ) : (
                  <h3 className="no-table-data">
                    No Outbound TDR data available
                  </h3>
                )}
              </Tab>
            </Tabs>
          </div>
        </>
      )}
    </>
  );
}

export default KPI;
