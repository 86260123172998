import React, { useEffect, useState } from "react";
import "./Monitoring.less";
import { useConfig } from "../../contexts/configContext";
import { useSnackbar } from "../../contexts/snackbarContext";
import Spinner from "../UI/Spinner/Spinner";
import useHttpApi from "../useHttpApi";
import ListTable from "../SharedComponents/ListTable";
import axios from "axios";
import { Button } from "react-bootstrap";

function Monitoring(props) {
  const [alarmTable, setalarmTable] = useState([]);
  const [fetchedAlarmTable, setfetchedAlarmTable] = useState(null);
  const config = useConfig();
  const snackbar = useSnackbar();
  const httpApi = useHttpApi();

  const header = [
    {
      title: "Object Instance",
      field: "objectInstance",
      size: "12%",
    },
    {
      title: "Notification ID",
      field: "notificationId",
      size: "9%",
    },
    {
      title: "Notification Type",
      field: "notificationType",
      size: "9%",
    },
    {
      title: "Alarm ID",
      field: "alarmId",
      size: "6%",
    },
    {
      title: "Probable Cause",
      field: "probableCause",
      size: "11%",
    },
    {
      title: "Specific Problem",
      field: "specificProblem",
      size: "9%",
    },
    {
      title: "Severity",
      field: "perceivedSeverity",
      size: "7%",
    },
    {
      title: "Involved Counterpart",
      field: "involvedCounterpart",
      size: "9%",
    },
    {
      title: "Event Time",
      field: "eventTime",
      size: "12%",
    },
    {
      title: "",
      field: "",
      size: "3%",
      type: "show",
    },
  ];

  useEffect(() => {
    props.setBreadcrumb("", "Monitoring", "");
  }, []);

  useEffect(() => {
    getAlarmTable().then((res) => {
      if (res !== -1) setfetchedAlarmTable(res);
    });
  }, []);

  const getAlarmTable = async () => {
    const data = await httpApi.callGetAPI({
      url: `json/${config.configInfo.protectionGroupID}alarm-table`,
      snack: {
        info: { status: "info", message: `There are no alarm data to show.` },
      },
    });
    return data || -1;
  };

  const resetAlarms = async () => {
    const data = await httpApi.callPostAPI({
      url: `json/${config.configInfo.protectionGroupID}alarm-table`,
      data: {},
      snack: {
        info: {
          status: "success",
          message: `Operation successfully completed!`,
        },
      },
      successCallback: () =>
        getAlarmTable().then((res) => {
          if (res !== -1) setfetchedAlarmTable(res);
        }),
    });
    return data || -1;
  };

  const buildAlarmTable = (fetchedAlarmTable) => {
    const _alarmTable = new Set();
    //getting the mib object
    Object.entries(fetchedAlarmTable).map((k, v) => {
      const mib = k[1];
      //extracting the mib id and the alarm object
      return Object.entries(mib).map((k1, v1) => {
        const alarmObject = k1[1];
        //build the alarm object by checking the mib id on the mibTable (in the config file)
        return alarmObject.map((alarm) => {
          if (alarm.model) {
            alarm.model.involvedCounterpart =
              alarm.model.additionalInformation.counterPart;

            // alarm.model.notificationType!=='notifyClearedAlarm' && _alarmTable.add(alarm.model)
            _alarmTable.add(alarm.model);
          }
          return alarm;
        });
      });
    });

    return Array.from(_alarmTable);
  };

  useEffect(() => {
    if (fetchedAlarmTable) {
      try {
        const _alarmTable = buildAlarmTable(fetchedAlarmTable);
        setalarmTable(_alarmTable);
      } catch (error) {
        snackbar.setSnackbarInfo({
          status: "error",
          message: `<b>An error occurred:</b> ${error
            .toString()
            .replace("Error:", "")}`,
        });
      }
    }
  }, [config.namespace, fetchedAlarmTable]);

  return (
    <>
      {config.loading ? (
        <Spinner show={config.loading} />
      ) : (
        <div className="page">
          {alarmTable.length > 0 ? (
            <>
              <ListTable
                elements={alarmTable}
                header={header}
                sortable
                searchable
                collapsible
                dense
                cellActions={[
                  {
                    elementName: "alarm",
                    type: "show",
                    size: header.find(
                      (h) => h.hasOwnProperty("type") && h.type === "show"
                    )?.size,
                  },
                ]}
              ></ListTable>
              <div
                className="rowDiv j-right list-group"
                style={{ marginRight: "1.5%", marginTop: "0px" }}
              >
                <Button
                  onClick={() => resetAlarms()}
                  style={{ marginLeft: "20px" }}
                >
                  RESET ALL
                </Button>
              </div>
            </>
          ) : (
            <h3 className="no-table-data">No Alarms data available</h3>
          )}
        </div>
      )}
    </>
  );
}

export default Monitoring;
